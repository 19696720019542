<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div
      class="
        col-7
        p-0
        align-self-center
        font-weight-bold
        d-flex
        align-items-center
      "
    >
      <h5 class="d-inline mr-2 font-weight-bold">
        {{ $t("message.patient_conclusions") }}
      </h5>
      <crm-refresh @c-click="refresh()"></crm-refresh>
    </div>
    <div
      class="
        col-5
        align-items-center align-self-center
        text-right
        pr-0
        d-flex
        justify-content-end
      "
    >
      <el-button
        v-can="'currencies.create'"
        @click="drawerCreate = true"
        icon="el-icon-circle-plus-outline"
      >
        {{ $t("message.create") }}
      </el-button>
    </div>
    <table
      style="background: #fff"
      class="table table-bordered table-hover"
      v-loading="loadingData"
    >
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <!-- <crm-th :column="columns.patient_id" :sort="sort" @c-change="updateSort"></crm-th> -->
          <crm-th
            :column="columns.doctor_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.service_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.date_time"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.updated_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.doctor_id.show">
            <!-- <el-select filterable clearable :placeholder="columns.doctor_id.title" size="mini"
                            v-model="filterForm.doctor_id">
                            <el-option v-for="item in states" :key="item.name + item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select> -->
          </th>
          <th v-if="columns.service_id.show">
            <el-input
              size="mini"
              v-model="filterForm.service_id"
              :placeholder="columns.service_id.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.date_time.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.date_time"
              :placeholder="columns.date_time.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.updated_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="item in list" :key="item.id" class="cursor-pointer">
          <td v-if="columns.id.show">{{ item.id }}</td>
          <!-- <td v-if="columns.patient_id.show">{{ item.patient_id }}</td> -->
          <td v-if="columns.doctor_id.show">
            {{
              item.doctor
                ? item.doctor.name +
                  " " +
                  (item.doctor.surname ? item.doctor.surname : "")
                : ""
            }}
          </td>
          <td v-if="columns.service_id.show">
            {{ item.service ? item.service.name : " - " }}
          </td>
          <td v-if="columns.date_time.show">
            {{ item.date_time  }}
          </td>
          <td v-if="columns.updated_at.show">
            {{ item.updated_at  }}
          </td>
          <td v-if="columns.created_at.show">
            {{ item.created_at  }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <el-button
              round
              @click="show(item)"
              size="mini"
              type="primary"
              icon="el-icon-view"
            ></el-button>
            <el-button
              round
              @click="edit(item)"
              size="mini"
              type="success"
              icon="el-icon-edit"
            ></el-button>
            <el-button
              round
              @click="destroy(item)"
              size="mini"
              type="danger"
              icon="el-icon-delete"
            ></el-button>
            <!-- <crm-setting-dropdown :model="item" name="conclusions" :actions="actions" @edit="edit" @delete="destroy">
                        </crm-setting-dropdown> -->
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
      title="Новый заключение"
      :visible.sync="drawerCreate"
      size="100%"
      :drawer="drawerCreate"
      @close="reloadIfChanged"
      @closed="afterClose()"
    >
      <div>
        <crm-create
          :reloadModel="reopenUpdate"
          @c-close="reloadIfChanged('drawerCreate')"
          ref="createDrawer"
        ></crm-create>
      </div>
    </el-drawer>
    <el-drawer
      title="Изменить заключение"
      :visible.sync="drawerUpdate"
      size="70%"
      :drawer="drawerUpdate"
      @open="reopenUpdate = true"
      @close="reopenUpdate = false"
      @closed="emptyModel"
    >
      <div>
        <crm-update
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="reloadIfChanged('drawerUpdate')"
          @closed="emptyModel"
        ></crm-update>
      </div>
    </el-drawer>
    <el-drawer
      title="Показать заключение"
      :visible.sync="drawerShow"
      size="70%"
      :drawer="drawerShow"
      @open="reopenShow = true"
      @close="reopenShow = false"
      @closed="emptyModel"
    >
      <div>
        <crm-show
          :reloadModel="reopenShow"
          @open="reopenShow = true"
          @c-close="reloadIfChanged('drawerShow')"
          @closed="emptyModel"
        ></crm-show>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./../createConclusion";
import CrmUpdate from "./components/crm-update";
import CrmShow from "./components/crm-show";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  name: "conclusionsList",
  components: {
    CrmUpdate,
    CrmCreate,
    CrmShow,
  },
  data() {
    return {
      drawerCreate: false,
      drawerUpdate: false,
      drawerShow: false,
      reopenUpdate: false,
      reopenShow: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "conclusions/list",
      columns: "conclusions/columns",
      pagination: "conclusions/pagination",
      filter: "conclusions/filter",
      sort: "conclusions/sort",
      states: "states/list",
      model: "orders/relation_model",
      user_id: "auth/id",
    }),
  },
  async created() {},
  async mounted() {},
  watch: {
    columns: {
      handler: function () {},
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "conclusions/index",
      updateSort: "conclusions/updateSort",
      updateFilter: "conclusions/updateFilter",
      updateColumn: "conclusions/updateColumn",
      updatePagination: "conclusions/updatePagination",
      showModel: "conclusions/show",
      editModel: "conclusions/show",
      empty: "conclusions/empty",
      delete: "conclusions/destroy",
      refreshData: "conclusions/refreshData",
    }),
    fetchData() {
      let patient = this.model.patient ? this.model.patient : "";
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
        service: true,
        patient_id: patient.id,
        doctor_id: this.user_id,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => {});
    },
    async show(model) {
      this.$loadingCursor("wait");
      await this.showModel(model.id)
        .then((res) => {
          this.$loadingCursor("default");
          this.drawerShow = true;
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    destroy(model) {
      this.$confirm("Вы действительно хотите это сделать?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмен",
        type: "warning",
      })
        .then(() => {
          this.$loadingCursor("wait");
          this.delete(model.id)
            .then((res) => {
              this.$alert(res);
              this.$loadingCursor("default");
              // this.fetchData()
            })
            .catch((err) => {
              this.$loadingCursor("default");
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Операция отменена",
          });
        });
    },
    emptyModel() {
      this.empty();
    },
    afterClose() {
      this.$refs["createDrawer"].afterClosed();
    },
  },
};
</script>
