<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <table
      style="background: #fff"
      class="table table-bordered table-hover"
      v-loading="loadingData"
    >
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <!-- <crm-th :column="columns.patient_id" :sort="sort" @c-change="updateSort"></crm-th> -->
          <crm-th
            :column="columns.doctor_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.date_time"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.updated_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <!-- <th v-if="columns.patient_id.show">
                        <el-input size="mini" v-model="filterForm.patient_id" :placeholder="columns.patient_id.title" clearable>
                        </el-input>
                    </th> -->
          <th v-if="columns.doctor_id.show">
            <!-- <el-select filterable clearable :placeholder="columns.doctor_id.title" size="mini"
                            v-model="filterForm.doctor_id">
                            <el-option v-for="item in states" :key="item.name + item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select> -->
          </th>
          <th v-if="columns.date_time.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.date_time"
              :placeholder="columns.date_time.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.updated_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="item in list" :key="item.id" class="cursor-pointer">
          <td v-if="columns.id.show">{{ item.id }}</td>
          <!-- <td v-if="columns.patient_id.show">{{ item.patient_id }}</td> -->
          <td v-if="columns.doctor_id.show">
            {{
              item.doctor
                ? item.doctor.name +
                  " " +
                  (item.doctor.surname ? item.doctor.surname : "")
                : ""
            }}
          </td>
          <td v-if="columns.date_time.show">
            {{ item.date_time }}
          </td>
          <td v-if="columns.updated_at.show">
            {{ item.updated_at }}
          </td>
          <td v-if="columns.created_at.show">
            {{ item.created_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <el-button
              round
              @click="show(item)"
              size="mini"
              type="primary"
              icon="el-icon-view"
              >{{ $t("message.show_0") }}</el-button
            >
            <!-- <crm-setting-dropdown :model="item" name="conclusions" :actions="actions" @edit="edit" @delete="destroy">
                        </crm-setting-dropdown> -->
          </td>
        </tr>
      </transition-group>
    </table>
    <!-- <el-drawer title="Новый тег" :visible.sync="drawerCreate" size="60%" :drawer="drawerCreate" @close="reloadIfChanged" >
            <div>
                <crm-create @c-close="closeDrawer"></crm-create>
            </div>
        </el-drawer> -->
    <el-drawer
      title="Изменить тег"
      :visible.sync="drawershow"
      size="80%"
      :drawer="drawershow"
      @open="reopenshow = true"
      @close="reopenshow = false"
      @closed="emptyModel"
    >
      <div>
        <crm-update
          :reloadModel="reopenshow"
          @open="reopenshow = true"
          @c-close="reloadIfChanged('drawershow')"
          @closed="emptyModel"
        ></crm-update>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-laboratory-show";
import axios from "axios";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  name: "conclusionsList",
  components: {
    CrmUpdate,
    CrmCreate,
  },
  data() {
    return {
      drawerCreate: false,
      drawershow: false,
      reopenshow: false,
      backUrl: process.env.VUE_APP_URL_DOCS,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "laboratoryConclusions/list",
      columns: "laboratoryConclusions/columns",
      token: "auth/token",
      pagination: "laboratoryConclusions/pagination",
      filter: "laboratoryConclusions/filter",
      sort: "laboratoryConclusions/sort",
      states: "states/list",
      model: "orders/relation_model",
      user_id: "auth/id",
    }),
  },
  async created() {},
  async mounted() {},
  watch: {
    columns: {
      handler: function () {},
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "laboratoryConclusions/index",
      updateSort: "laboratoryConclusions/updateSort",
      updateFilter: "laboratoryConclusions/updateFilter",
      updateColumn: "laboratoryConclusions/updateColumn",
      updatePagination: "laboratoryConclusions/updatePagination",
      showModel: "laboratoryConclusions/show",
      empty: "laboratoryConclusions/empty",
      delete: "laboratoryConclusions/destroy",
      refreshData: "laboratoryConclusions/refreshData",
    }),
    fetchData() {
      if (this.model) {
        let patient = this.model.patient ? this.model.patient : "";
        const query = {
          ...this.filter,
          ...this.pagination,
          ...this.sort,
          patient_id: patient.id,
          patient: true,
          doctor_id: this.user_id,
          order_id: this.$route.params.id,
        };
        if (!this.loadingData) {
          this.loadingData = true;
          this.updateList(query)
            .then((res) => {
              // console.log(res);

              this.loadingData = false;
            })
            .catch((err) => {
              this.loadingData = false;
            });
        }
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },

    async show(model) {
      axios({
        url: this.backUrl + "/get_file_type/" + model.order_id,
        method: "GET",
      })
        .then((res) => {
          if (res.data == "html") {
            this.$loadingCursor("wait");
            this.showModel(model.id)
              .then((res) => {
                this.$loadingCursor("default");
                this.drawershow = true;
              })
              .catch((err) => {
                this.$loadingCursor("default");
              });
          } else {
            axios({
              url: this.backUrl + "/check_waiting_docs/" + model.order_id,
              method: "GET",
            })
              .then((res_doc) => {
                if (res_doc.data.result == true) {
                  var name =
                    model.order_id +
                    " " +
                    model.patient.surname +
                    " " +
                    model.patient.first_name;

                  var link = document.createElement("a");
                  link.setAttribute(
                    "href",
                    this.backUrl +
                      "/file_orientation?order_id=" +
                      model.order_id +
                      "&name=" +
                      name +
                      "&user=" +
                      this.token +
                      "&status=show" +
                      "&user_type=laborant"
                  );
                  link.setAttribute("target", "_blank");
                  link.click();

                  return;
                } else this.$notify({message: "Идет сохранение файла подождите немного", type: 'warning', title: 'Предупреждение' });
              })
              .catch((err_doc) => {
                this.$notify({message: "Идет сохранение файла подождите немного", type: 'warning', title: 'Предупреждение' });
              });
          }
        })
        .catch((err) => {});
    },

    emptyModel() {
      this.empty();
    },
  },
};
</script>
