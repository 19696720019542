<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t("message.show_conclusions") }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button @click="editLaboratoryConclusion">{{
                $t("message.update")
              }}</el-button>
              <!-- <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save_and_close") }}</el-button
              > -->
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <el-row>
            <el-col class="ml-5" :span="8">
              {{ $t("message.doctor_name") }} :
              <b>{{
                model.doctor
                  ? model.doctor.name +
                    " " +
                    (model.doctor.surname ? model.doctor.surname : "") +
                    " " +
                    (model.doctor.patronymic ? model.doctor.patronymic : "")
                  : ""
              }}</b>
            </el-col>
            <el-col :span="8">
              {{ $t("message.date") }} :
              <b>{{ model.date_time }}</b>
            </el-col>
            <el-divider></el-divider>
            <el-col :span="24">
              <el-card class="ml-5" style="width: 826px" shadow="always">
                <!-- <el-card shadow="always"> -->
                <div class="content-table" v-html="model.content"></div>
                <!-- <Tinymce ref="editor" class="naw_Editor" v-model="form.content" :height="800" /> -->
                <!-- </el-card> -->
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <el-drawer
      title="Изменить тег"
      :visible.sync="drawerUpdate"
      size="60%"
      :append-to-body="true"
      :drawer="drawerUpdate"
      @open="reopenUpdate = true"
      @close="reopenUpdate = false"
    >
      <div>
        <crm-update
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="afterCloseDrawer"
        ></crm-update>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import CrmUpdate from "./crm-laboratory-update";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import Tinymce from "@/components/Tinymce";

export default {
  mixins: [form, list],
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  components: {
    Tinymce,
    CrmUpdate,
  },
  data() {
    return {
      // editor: DecoupledEditor,
      editorConfig: {},
      reopenUpdate: false,
      drawerUpdate: false,
    };
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  created() {},
  async mounted() {},
  computed: {
    ...mapGetters({
      rules: "laboratoryConclusions/rules",
      model: "laboratoryConclusions/model",
      columns: "laboratoryConclusions/columns",
    }),
  },
  methods: {
    ...mapActions({
      showModel: "laboratoryConclusions/show",
      update: "laboratoryConclusions/update",
    }),
    editLaboratoryConclusion() {
      this.showModel(this.model.id)
        .then((res) => {
          this.$loadingCursor("default");
          this.drawerUpdate = true;
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    afterCloseDrawer() { 
      
      this.reloadIfChanged("drawerUpdate");
      this.showModel(this.model.id);
    },
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    submit(close = true) {
      this.loadingButton = true;
      this.update({ id: this.form.id, data: this.form })
        .then((res) => {
          this.loadingButton = false;
          this.$emit("c-close", { drawer: "drawerUpdate" });
          this.$alert(res);
          this.$refs["form"];
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$alert(err);
        });
    },

    resetForm(formName) {
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>
